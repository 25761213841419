import { InteractionModelData } from 'types/shared'

const findActivityModelWithDecisionModel = (
  interactionModelData?: InteractionModelData
) => {
  const steps = interactionModelData?.interaction_model_item_groups
  const allActivityModels = steps?.map((step) => step.activity_models).flat()

  return allActivityModels?.find(
    (activityModel) => activityModel.sequence_decision_model
  )
}

const hasPendingDecisionModel = (
  interactionModelData?: InteractionModelData
) => {
  const activityModelWithDecisionModel =
    findActivityModelWithDecisionModel(interactionModelData)

  return (
    Boolean(activityModelWithDecisionModel) &&
    !activityModelWithDecisionModel?.value
  )
}

export default hasPendingDecisionModel
