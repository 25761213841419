import { useEffect } from 'react'
import { useAppDispatch } from 'state/store'
import geolocation from '../../../utils/geolocation'
import { interactionModelOperations } from '../redux'
import { InteractionModel } from 'types/shared'

const useGeolocation = (geolocationState: InteractionModel['geolocation']) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!geolocationState) {
      if (geolocation.isAvailable()) {
        geolocation
          .getCurrentPosition()
          .then((position) => {
            const {
              coords: { latitude },
              coords: { longitude }
            } = position

            dispatch(
              interactionModelOperations.setGeolocation({
                latitude,
                longitude
              })
            )
          })
          .catch((error) => {
            console.error(error.message)
          })
      }
    }
  }, [dispatch, geolocationState])

  return null
}

export default useGeolocation
