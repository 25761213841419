import { InteractionModelData, ValidationStatus } from 'types/shared'

type UpdateActivityModelStatusProps = {
  interactionModelData?: InteractionModelData
  activityModelUuid: string
  validationStatus: ValidationStatus
  helperText: string
}

export default function updateActivityModelStatus({
  interactionModelData,
  activityModelUuid,
  validationStatus,
  helperText
}: UpdateActivityModelStatusProps) {
  const steps = interactionModelData?.interaction_model_item_groups || []

  steps.forEach((step) => {
    step.activity_models.forEach((activityModel) => {
      if (activityModel.uuid === activityModelUuid) {
        activityModel.validationStatus = validationStatus
        activityModel.helperText = helperText
      }
    })
  })

  return interactionModelData
}
