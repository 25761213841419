import {
  Button,
  Col,
  Icon,
  Row,
  Text,
  Unifree,
  YesObject
} from '@yes.technology/react-toolkit'
import { useTranslation } from 'react-i18n-lite'
import React, { useEffect, useState } from 'react'

import ActivityWithActions from '../ActivityWithActions/ActivityWithActions'
import { SELECT_AND_LINK_ACTION } from 'modules/interaction/hooks/useActions'
import { useSelectAndLinkFrameContext } from '../../SelectAndLinkFrame/SelectAndLinkFrameContext'
import FileUploader from 'modules/shared/FileUploader'
import { useGetObjectS3, useUploadS3 } from 'modules/interaction/hooks'
import { useDocument } from 'modules/shared/hooks'
import { formatFileSize } from 'modules/shared/utils/formatFileSize'
import { ActivityModel } from 'types/shared'
import { ActivityInputProps } from '../Activity.types'
import { SafeHtmlContent } from 'modules/shared/SafeHtmlContent'
import styled from 'styled-components'
import { useAppDispatch } from 'state/store'

interface ActivityDocumentFileProps {
  inputValue: ActivityModel['value']
  onChange: ActivityInputProps['handleChange']
  label: string
  explanationText?: string
  required?: boolean
  actions?: string[]
  maxSizeInBytes?: number
  accept?: string
}

interface Values {
  uuid?: string
  uuid_status?: string
  contentType?: string
  size?: string
  url?: string
  des?: string
  filename?: string
  originalFilename?: string
}

const TextAlignJustify = styled.span`
  text-align: justify;
`

const ActivityDocumentFile = ({
  inputValue,
  onChange,
  label,
  explanationText,
  required,
  actions = [],
  maxSizeInBytes,
  accept
}: ActivityDocumentFileProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { createOrUpdateDocument } = useDocument()
  const { uploadFile } = useUploadS3()
  const { getObject } = useGetObjectS3()
  const { setSelectAndLinkData } = useSelectAndLinkFrameContext()

  const [values, setValues] = useState<Values>()

  const isNonEmptyString = (value: string | undefined) =>
    value !== undefined && value !== ''

  const isInformational = isNonEmptyString(values?.uuid)

  useEffect(() => {
    if (typeof inputValue === 'string' && inputValue) {
      setValues(JSON.parse(inputValue))
    }
  }, [inputValue])

  const handleActionClick = (action: string, uniselection?: boolean) => {
    if (action === SELECT_AND_LINK_ACTION) {
      setSelectAndLinkData({
        uuidObjectclass: window.documentObjectclassUuid,
        callback: async (values) => {
          const object: YesObject = values?.[0]
          if (object) {
            const { statusDes } = await createOrUpdateDocument({
              uuid: object.uuid
            })

            if (statusDes) {
              onChange(
                JSON.stringify({
                  uuid: object.uuid,
                  uuid_status: statusDes,
                  des: object.des,
                  contentType: object.content_type
                })
              )
            }
          }
        },
        uniselection
      })
    }
  }

  const handleFileUploadSuccess = async (
    url: string,
    filename: string,
    originalFilename: string
  ) => {
    const file = await getObject(filename)

    const previousValues =
      typeof inputValue === 'string' && inputValue !== ''
        ? JSON.parse(inputValue)
        : inputValue

    const { document, statusDes } = await createOrUpdateDocument({
      uuid: values?.uuid,
      content_type: file.contentType,
      url,
      complement: JSON.stringify({ size: file.contentLength })
    })

    onChange(
      JSON.stringify({
        ...previousValues,
        uuid: document.uuid,
        uuid_status: statusDes,
        des: document.des,
        contentType: file.contentType,
        size: file.contentLength,
        url,
        filename,
        originalFilename
      })
    )

    dispatch({
      type: 'api/RECEIVED',
      data: ''
    })
  }

  const handleFileUploadError = () => {
    dispatch({
      type: 'api/RECEIVED',
      data: ''
    })
  }

  const handleLocalFileUpload = (file: File) => {
    dispatch({
      type: 'api/REQUESTED',
      data: ''
    })

    uploadFile({
      file,
      onSuccess: handleFileUploadSuccess,
      onError: handleFileUploadError,
      fileType: file.type
    })
  }

  const handleClearData = () => {
    onChange('')
    setValues(undefined)
  }

  const handleDescriptionChange = (value: string) => {
    onChange(
      JSON.stringify({
        ...values,
        des: value
      })
    )
  }

  const handleSaveDescription = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!inputValue || !values?.url) {
      return
    }

    // workaround to solve a react issue when an event triggers a re-render the other events don't fire. As discussed this workaround should be deleted in a future refactor
    setTimeout(async () => {
      const parsedValues =
        typeof inputValue === 'string' && inputValue !== ''
          ? JSON.parse(inputValue)
          : inputValue
      const { document, statusDes } = await createOrUpdateDocument({
        uuid: parsedValues.uuid,
        des: event.target.value
      })

      onChange(
        JSON.stringify({
          ...parsedValues,
          uuid: document.uuid,
          des: event.target.value,
          uuid_status: statusDes
        })
      )
    }, 500)
  }

  return (
    <>
      {label && (
        <Row>
          <Col lg={12}>
            <TextAlignJustify>
              <Text as='label' variant='label-03'>
                {label}
              </Text>
            </TextAlignJustify>
          </Col>
        </Row>
      )}

      {explanationText && (
        <Row className='mt-2'>
          <Col lg={12}>
            <TextAlignJustify>
              <Text as='p' variant='content-04'>
                <SafeHtmlContent html={explanationText} />
              </Text>
            </TextAlignJustify>
          </Col>
        </Row>
      )}

      <Row className='mt-2'>
        <Col lg={12}>
          <Unifree
            label={t('activity-document-file.labels.des')}
            value={values?.des || ''}
            onChange={handleDescriptionChange}
            onBlur={handleSaveDescription}
            data-testid='description-field'
            disabled={!values?.url}
            required={required}
            placeholder={t('activity-document-file.placeholders.des')}
          />
        </Col>
      </Row>

      <Row className='mt-2'>
        <Col lg={12}>
          <div className='d-flex align-items-end'>
            <div className='flex-grow-1'>
              <ActivityWithActions
                {...{ actions, onClickAction: handleActionClick }}
              >
                <Unifree
                  label={t('activity-document-file.labels.document-id')}
                  disabled={!isNonEmptyString(values?.uuid)}
                  informational={isInformational}
                  value={values?.uuid || ''}
                  required={required}
                />
              </ActivityWithActions>
            </div>

            {!values?.url ? (
              <FileUploader
                onChange={handleLocalFileUpload}
                accept={accept}
                maxSizeInBytes={maxSizeInBytes}
              />
            ) : (
              <Button
                variant='secondary'
                onClick={handleClearData}
                style={{ width: '40px', marginLeft: '4px' }}
                data-testid='clear-button'
              >
                <Icon iconName='Cleaner' />
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col lg={12}>
          <Unifree
            label={t('activity-document-file.labels.status')}
            disabled={!isNonEmptyString(values?.uuid_status)}
            informational={isInformational}
            value={values?.uuid_status || ''}
            required={required}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col md={6}>
          <Unifree
            label={t('activity-document-file.labels.content-type')}
            disabled={!isNonEmptyString(values?.contentType)}
            informational={isInformational}
            value={values?.contentType || ''}
            required={required}
          />
        </Col>
        <Col md={6}>
          <Unifree
            label={t('activity-document-file.labels.size')}
            disabled={!isNonEmptyString(values?.size)}
            informational={isInformational}
            value={values?.size ? formatFileSize(Number(values.size)) : ''}
            required={required}
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col lg={12}>
          <Unifree
            label={t('activity-document-file.labels.file-name')}
            disabled={!isNonEmptyString(values?.originalFilename)}
            informational={isInformational}
            value={values?.originalFilename || ''}
            required={required}
          />
        </Col>
      </Row>
    </>
  )
}

export default ActivityDocumentFile
