import { useTranslation } from 'react-i18n-lite'

import ActivityValue from './ActivityValue'
import { formatFileSize } from 'modules/shared/utils/formatFileSize'

interface ActivityDocumentPhotoProps {
  values: string
}

const ActivityDocumentPhoto = ({ values }: ActivityDocumentPhotoProps) => {
  const { t } = useTranslation()

  const obj = JSON.parse(values)

  return (
    <>
      <ActivityValue
        label={t('activity-document-photo.labels.des')}
        value={obj.des}
      />
      <ActivityValue
        label={t('activity-document-photo.labels.uuid-foto')}
        value={obj.uuid}
      />
      <ActivityValue
        label={t('activity-document-photo.labels.status')}
        value={obj.uuid_status}
      />
      <div className='row'>
        <div className='col-6'>
          <ActivityValue
            label={t('activity-document-photo.labels.content-type')}
            value={obj.contentType}
          />
        </div>
        <div className='col-6'>
          <ActivityValue
            label={t('activity-document-photo.labels.size')}
            value={obj.size ? formatFileSize(Number(obj.size)) : ''}
          />
        </div>
      </div>
      <div className='w-100 d-flex justify-content-center align-items-center'>
        {obj?.file ? (
          <img
            src={obj.file}
            className='img-fluid rounded'
            alt={`${obj?.des || 'Photo'}`}
          />
        ) : (
          <div className='w-100 webcam-no-image rounded'>
            {t('activity-document-photo.no-image')}
          </div>
        )}
      </div>
    </>
  )
}

export default ActivityDocumentPhoto
