import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18n-lite'
import { ExplicitSelectionButton } from '@yes.technology/react-toolkit'
import { SafeHtmlContent } from 'modules/shared/SafeHtmlContent'

const Container = styled.div``

const TermsCheckboxContainer = styled.div`
  margin: 1rem 0;
`

const Terms = styled.div`
  max-height: 400px;
  padding: 1rem;
  text-align: justify;
  text-indent: 1rem;
  border: 2px solid #abbed1;
  border-radius: 0.5rem;
`

function ActivityInputAgreementTerm({ onChange, inputValue, terms }) {
  const [isChecked, setIsChecked] = useState(inputValue)
  const { t } = useTranslation()

  const handleChange = () => {
    const updatedValue = isChecked === '1' ? '0' : '1'

    setIsChecked(updatedValue)
    onChange(updatedValue)
  }

  return (
    <Container>
      <Terms className='overflow-auto'>
        <SafeHtmlContent html={terms} />
      </Terms>
      <TermsCheckboxContainer>
        <ExplicitSelectionButton
          id='terms-accept'
          heightClass='semiSmall'
          direction='vertical'
          optionLabelsPosition='right'
          selectionType='uniselection'
          checkedOption={isChecked === '1' && 'terms-accept'}
          options={[
            {
              uuid: 'terms-accept',
              des: t('activity-input-agreement-term.accept')
            }
          ]}
          onChange={handleChange}
        />
      </TermsCheckboxContainer>
    </Container>
  )
}

export default ActivityInputAgreementTerm
