import styled from 'styled-components'
import { SafeHtmlContent } from 'modules/shared/SafeHtmlContent'

type ActivitySafeHTMLProps = {
  htmlContent?: string
}

const TextAlignJustify = styled.div`
  text-align: justify;
`

const ActivitySafeHTML = ({ htmlContent }: ActivitySafeHTMLProps) => {
  return htmlContent ? (
    <TextAlignJustify>
      <SafeHtmlContent html={htmlContent} />
    </TextAlignJustify>
  ) : null
}

export default ActivitySafeHTML
