import { useEffect } from 'react'
import Yta from './Yta'
import useFetchYtaObjects from './useFetchYtaObjects'
import { useAppSelector } from 'state/store'

/*
  todo:
  - renovar a sessão do usuário antes de iniciar o teste
*/

type ActivityYtaProps = {
  sourceDataContainerVariable?: string
}

const ActivityYta = ({ sourceDataContainerVariable }: ActivityYtaProps) => {
  const { interactionModel } = useAppSelector(
    ({ interactions }) => interactions
  )

  const { ytaObjects, fetchYtaObjects } = useFetchYtaObjects()

  useEffect(() => {
    fetchYtaObjects({
      workflowUuid: interactionModel?.workflowUuid,
      sourceDataContainerVariable
    })
  }, [
    fetchYtaObjects,
    interactionModel?.workflowUuid,
    sourceDataContainerVariable
  ])

  return (
    <>
      <Yta {...{ ytaObjects }} />
    </>
  )
}

export default ActivityYta
