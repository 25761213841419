import { UnknownAction } from 'redux'
import { Api } from 'types/shared'

type ApiAction = {
  type: 'api/REQUESTED'
  data: {
    requestUuid: string
    status?: number
  }
}
/**
 * Reducer
 */
export default function api(
  state: Api = { requests: [], isPending: false },
  action: ApiAction | UnknownAction
): Api {
  switch (action.type) {
    case 'api/REQUESTED': {
      const newState = { ...state }
      newState.requests.push((action as ApiAction).data.requestUuid)
      newState.isPending = true
      return newState
    }
    case 'api/FAILED':
    case 'api/RECEIVED': {
      const requests = state.requests.filter((i) => {
        return i !== (action as ApiAction).data.requestUuid
      })
      return {
        ...state,
        requests: requests,
        isPending: requests.length > 0
      }
    }
    case 'api/ERROR': {
      return {
        ...state,
        isError: true,
        status: (action as ApiAction)?.data?.status
      }
    }
    case 'login/TOKEN_EXPIRED':
      return {
        requests: [],
        isPending: false
      }
  }

  return state
}
