import { useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18n-lite'

import { interactionModelOperations } from '../redux'
import { useAuthentication } from 'authentication'
import { useAppDispatch } from 'state/store'
import { InteractionModel } from 'types/shared'

const useInitialConfig = (interactionModel: InteractionModel) => {
  const dispatch = useAppDispatch()

  const { username } = useAuthentication()

  const { language: lang } = useTranslation()

  const { user, dateStartInteraction, language } = interactionModel

  useEffect(() => {
    if (!user && username && !dateStartInteraction && !language) {
      dispatch(
        interactionModelOperations.setInitialConfiguration(
          username,
          moment().format(),
          lang
        )
      )
    }
  }, [
    dispatch,
    interactionModel,
    username,
    lang,
    user,
    dateStartInteraction,
    language
  ])

  return null
}

export default useInitialConfig
