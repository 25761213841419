import { useAppDispatch } from 'state/store'
import { interactionModelOperations } from '../redux'
import {
  Activity,
  GeolocationDependencies,
  InteractionModel
} from 'types/shared'

const KEYS_GEOCODE_FROM_DEPENDENCY = {
  number: 'street_number',
  street: 'route',
  neighborhood: 'sublocality_level_1',
  city: 'administrative_area_level_2',
  state: 'administrative_area_level_1',
  country: 'country',
  zip_code: 'postal_code'
}

export const useCalcUserAddressFromLatLong = (
  geolocation_dependencies: GeolocationDependencies,
  coord?: InteractionModel['geolocation']
) => {
  const dispatch = useAppDispatch()

  const processUserAddressFromLatLong = () => {
    if (!coord) {
      return
    }

    const location = new google.maps.LatLng(coord.latitude, coord.longitude)
    const { geocode } = new window.google.maps.Geocoder()

    geocode({ location }, (results, status) => {
      if (status !== google.maps.GeocoderStatus.OK || !results) {
        return
      }

      const activities: Activity[] = []

      Object.keys(geolocation_dependencies).forEach((dependencyKey) => {
        if (!(dependencyKey in KEYS_GEOCODE_FROM_DEPENDENCY)) {
          return
        }

        const geocodeKey =
          KEYS_GEOCODE_FROM_DEPENDENCY[
            dependencyKey as keyof typeof KEYS_GEOCODE_FROM_DEPENDENCY
          ]

        const geocodeObject = results[0]?.address_components?.find(
          (obj) => obj.types?.includes(geocodeKey)
        )
        const des = geocodeObject?.long_name

        if (des) {
          activities.push({
            uuid_activity_model:
              geolocation_dependencies[
                dependencyKey as keyof GeolocationDependencies
              ],
            des
          })
        }
      })

      dispatch(interactionModelOperations.updateActivities(activities))
    })
  }

  return { processUserAddressFromLatLong }
}
