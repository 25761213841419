import { useState } from 'react'
import { useTranslation } from 'react-i18n-lite'

import Progress from '../../../shared/Progress'
import useUploadS3 from '../../hooks/useUploadS3'

/**
 * @deprecated
 */
function ActivityInputFileS3({
  id,
  inputValue: uploadedFileUrl,
  onChange,
  cssClass,
  readOnly,
  required,
  label
}) {
  const { t } = useTranslation()
  const [selectedFile, setSelectedFile] = useState(null)

  const { progress, setProgress, currentStatus, setCurrentStatus, uploadFile } =
    useUploadS3()

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0])
    setCurrentStatus(
      t('activity-input-upload-s3.status.selected-file', {
        btn: t('activity-input-upload-s3.btn-upload-des')
      })
    )
    setProgress(0)
  }

  const removeFile = () => {
    onChange('')
    setCurrentStatus(t('activity-input-upload-s3.status.awaiting-upload'))
  }

  const handleUploadFile = () => {
    uploadFile({
      file: selectedFile,
      onStart: () => {},
      onSuccess: handleOnSuccess,
      onError: handleOnError
    })
  }

  const handleOnSuccess = (url) => {
    onChange(url)
  }

  const handleOnError = (err) => {
    console.log(err)
  }

  return (
    <>
      {label && <label htmlFor={id}>{label}</label>}
      <div className='input-group'>
        <input
          type='file'
          id={id}
          className={cssClass}
          readOnly={readOnly}
          required={required}
          onChange={handleFileInput}
        />
        <button
          type='button'
          disabled={!(selectedFile && selectedFile.name)}
          className='btn btn-dark'
          onClick={handleUploadFile}
        >
          {t('activity-input-upload-s3.btn-upload-des')}
        </button>
      </div>

      <div className='card mt-2'>
        <div className='card-body'>
          {progress > 0 && progress < 100 && (
            <Progress valueNow={progress} heightProgress={2} />
          )}
          <div>
            {t('activity-input-upload-s3.status-des')}:{' '}
            <strong>{currentStatus}</strong>
          </div>
          {uploadedFileUrl && (
            <div className='border-top pt-2 mt-2'>
              {t('activity-input-upload-s3.saved-file-des')}:{' '}
              <strong>{uploadedFileUrl}</strong>{' '}
              <button
                type='button'
                className='btn btn-link btn-sm link-danger'
                onClick={removeFile}
              >
                {t('activity-input-upload-s3.btn-remove-file-des')}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ActivityInputFileS3
