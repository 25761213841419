import debounce from 'lodash.debounce'
import { useCallback, useEffect, useMemo } from 'react'

import api from '../../../utils/api'
import { VALIDATION_STATUSES } from '../../../utils/constants'
import { interactionModelOperations } from '../redux'
import {
  ActivityModel,
  InteractionModelData,
  ValidationStatus
} from 'types/shared'
import { useAppDispatch } from 'state/store'

const { updateActivityModelStatus } = interactionModelOperations

const validateUsingCallback = async (
  activityModel: ActivityModel
): Promise<{ validationStatus: ValidationStatus; helperText: string }> => {
  if (
    !activityModel.validation_activity_callback ||
    activityModel.value === ''
  ) {
    return { validationStatus: VALIDATION_STATUSES.PENDING, helperText: '' }
  }

  const reqObj = {
    content: activityModel.value
  }

  const validationCallback = activityModel.validation_activity_callback

  const onError = (e: Error) => ({ success: false, message: e.message })

  const { success, message = '' } = await api
    .postInputValidation(reqObj, validationCallback, false)
    .catch(onError)
  const validMessage = 'activity-input.validation.is_valid'

  return {
    validationStatus: success
      ? VALIDATION_STATUSES.VALID
      : VALIDATION_STATUSES.INVALID,
    helperText: success ? validMessage : message
  }
}

export default function useValidationCallbacks(
  interactionModelData?: InteractionModelData
) {
  const dispatch = useAppDispatch()

  const callPendingValidationCallbacks = useCallback(
    (oldInteractionModelData?: InteractionModelData) => {
      const steps = oldInteractionModelData?.interaction_model_item_groups || []

      steps.forEach((step) => {
        step.activity_models.forEach((activityModel) => {
          if (activityModel.validationStatus === VALIDATION_STATUSES.PENDING) {
            validateUsingCallback(activityModel).then(
              ({ validationStatus, helperText }) => {
                dispatch(
                  updateActivityModelStatus({
                    activityModelUuid: activityModel.uuid,
                    validationStatus,
                    helperText
                  })
                )
              }
            )
          }
        })
      })
    },
    [dispatch]
  )

  const debouncedValidationsCaller = useMemo(
    () => debounce(callPendingValidationCallbacks, 500),
    [callPendingValidationCallbacks]
  )

  useEffect(() => {
    debouncedValidationsCaller(interactionModelData)
  }, [debouncedValidationsCaller, interactionModelData])
}
