import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { createMigrate, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import * as reducers from './ducks'
import { migrations } from './migrations'
import api from '../utils/api'
import {
  TypedUseSelectorHook,
  shallowEqual,
  // eslint-disable-next-line no-restricted-imports
  useSelector,
  // eslint-disable-next-line no-restricted-imports
  useDispatch
} from 'react-redux'

const persistConfig = {
  key: 'root',
  storage,
  version: 0,
  blacklist: ['api'],
  migrate: createMigrate(migrations, { debug: false })
}

const initialState = {}

const appReducer = combineReducers(reducers)
const persistedReducer = persistReducer(persistConfig, appReducer)

const store = configureStore({
  reducer: persistedReducer,
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { api }
      },
      serializableCheck: false,
      immutableCheck: false
    }),
  devTools: process.env.NODE_ENV !== 'production'
})

export const persistor = persistStore(store)

export default store

export type AppStore = typeof store

export type ReduxState = ReturnType<AppStore['getState']>

export type AppDispatch = AppStore['dispatch']

export const useAppSelector: TypedUseSelectorHook<ReduxState> = (selector) =>
  useSelector(selector, shallowEqual)

export const useAppDispatch = () => useDispatch<AppDispatch>()
