import {
  sendMessageToParent,
  useDisplayUserError
} from '@yes.technology/react-toolkit'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18n-lite'

import {
  InteractionModel,
  InteractionModelData,
  InteractionModelItemGroup
} from 'types/shared'
import {
  saveInteraction,
  validateInteractionModelCallback,
  validateInteractionModelItemGroupCallback
} from '../../../utils/interactionModel'
import { interactionModelOperations } from '../redux'
import {
  getInvalidActivityModelsByItemGroup,
  getInvalidFieldsInStep,
  getStepsToBeShown,
  showMessageRequiredFields,
  updateStepsWithInvalidActivities
} from '../utils/validation'
import useAutomation from './useAutomation'
import { useAppDispatch } from 'state/store'

const useStep = (interactionModel: InteractionModel, onSave: () => void) => {
  const { t, language } = useTranslation()

  const dispatch = useAppDispatch()

  const [currentStep, setCurrentStep] = useState(1)
  const [uuidInteraction, setUuidInteraction] = useState(null)
  const [isLoadedSteps, setIsLoadedSteps] = useState(false)
  const [isInteractionFinished, setIsInteractionFinished] = useState(false)

  const steps: InteractionModelItemGroup[] = useMemo(() => {
    const steps = getStepsToBeShown(
      interactionModel?.data?.interaction_model_item_groups || []
    )
    return JSON.parse(JSON.stringify(steps))
  }, [interactionModel])

  const totalSteps = steps.length + 1
  const currentStepData = steps.length ? steps[currentStep - 1] : undefined

  const initialStepUuid = interactionModel.data?.initial_step_uuid
  const initialStepIndex =
    steps.findIndex((step) => step.uuid === initialStepUuid) + 1

  useEffect(() => {
    setCurrentStep(initialStepIndex || 1)
  }, [initialStepIndex])

  useEffect(() => {
    if (totalSteps === 1) return
    setIsLoadedSteps(true)
  }, [totalSteps])

  useEffect(() => {
    if (
      isInteractionFinished &&
      !interactionModel?.isFetching &&
      uuidInteraction
    ) {
      const informationCards = interactionModel?.data?.information_cards
      const hasConclusionCard = informationCards?.some(
        (card) => card.display_on === 'interaction-finished'
      )

      if (!hasConclusionCard) {
        sendMessageToParent(
          interactionModel.workflowUuid || '',
          uuidInteraction
        )
      }
    }
  }, [isInteractionFinished, interactionModel, uuidInteraction])

  const executeAutomation = useAutomation({ interactionModel })

  const executeAutomationsAfterSuccess = async () => {
    const automations = interactionModel?.data?.automations || []

    const sortedAutomations = automations
      .slice()
      .sort((firstAutomation, secondAutomation) => {
        if (firstAutomation.automation_type === 'redirect') return 1
        if (secondAutomation.automation_type === 'redirect') return -1
        return 0
      })

    for (const automation of sortedAutomations) {
      if (automation.trigger === 'submit-success') {
        await executeAutomation(automation)
      }
    }
  }

  const { displayUserError } = useDisplayUserError()

  const saveAndFinish = async (data: InteractionModelData) => {
    const invalidFields = steps.map(getInvalidFieldsInStep).flat()

    if (!showMessageRequiredFields(invalidFields, t)) {
      return false
    }

    if (data.validation_callback) {
      const responseValidationCallback = await validateInteractionModelCallback(
        data,
        data.validation_callback
      )
      if (!responseValidationCallback?.success) {
        displayUserError({ error: responseValidationCallback })
        return false
      }
    }

    const response = await saveInteraction(interactionModel, language).catch(
      (error) => {
        return {
          success: false,
          status: error?.status,
          message: error?.message || 'Failed Request'
        }
      }
    )

    if (!response.success && response.status !== 503) {
      displayUserError({
        title: t('step.save.failure-title'),
        userMessageFallback: t('step.save.failure-message'),
        error: response
      })

      console.error(response.message)

      return false
    }

    const newUuidInteraction = response?.data?.new_interaction_ids?.[0]
    setUuidInteraction(newUuidInteraction)
    executeAutomationsAfterSuccess()
    onSave?.()
    setIsInteractionFinished(true)
  }

  const afterNextButton = async (step: number) => {
    const currentInteractionModelItemGroup = steps[currentStep - 1]
    const listInvalidActivityModels = getInvalidActivityModelsByItemGroup(
      currentInteractionModelItemGroup,
      t
    )

    if (listInvalidActivityModels.length) {
      const validatedInteractionModelItemGroups =
        updateStepsWithInvalidActivities(
          interactionModel?.data?.interaction_model_item_groups || [],
          currentInteractionModelItemGroup,
          listInvalidActivityModels
        )
      dispatch(
        interactionModelOperations.updateItemGroups(
          validatedInteractionModelItemGroups
        )
      )
      return
    }

    if (currentInteractionModelItemGroup?.validation_group_callback) {
      const responseValidationCallback =
        await validateInteractionModelItemGroupCallback(
          currentInteractionModelItemGroup,
          currentInteractionModelItemGroup.validation_group_callback
        )
      if (!responseValidationCallback?.success) {
        displayUserError({ error: responseValidationCallback })
        return
      }
    }

    setCurrentStep(step)
  }

  const changeStep = (step: number) => {
    const finishingInteraction = step > totalSteps
    const isAnyStepWithValidation = step > currentStep

    // clicou no botão Salvar e Ativar
    if (finishingInteraction && interactionModel?.data) {
      saveAndFinish(interactionModel?.data)
    }

    // clicou no botão avançar
    // qualquer step, exceto finish e preview (sempre pelo botão next)
    if (!finishingInteraction && isAnyStepWithValidation) {
      afterNextButton(step)
    }

    // preview e também steps que estão voltando (botão previous)
    if (!finishingInteraction && !isAnyStepWithValidation) {
      setCurrentStep(step)
    }

    window.scrollTo(0, 0)
  }

  return {
    totalSteps,
    currentStep,
    currentStepData,
    uuidInteraction,
    setCurrentStep,
    setUuidInteraction,
    initialStepIndex,
    changeStep,
    isLoadedSteps,
    isInteractionFinished
  }
}

export default useStep
