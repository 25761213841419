import { BreakpointStart, Text } from '@yes.technology/react-toolkit'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media (min-width: ${BreakpointStart.md}px) {
    gap: 1rem;
  }
`

export const Agreement = styled(Text).attrs({
  as: 'div',
  variant: 'content-03'
})`
  max-height: 40vh;
  overflow: auto;
  border: 2px solid #abbed1;
  border-radius: 4px;
  padding: 1rem;
  text-align: justify;
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #abbed1;

  > img {
    max-width: 100%;
  }
`

export const CanvasContainer = styled.div`
  position: relative;

  > button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: none;
    border: 1px solid transparent;
    padding: 0;
    margin: 0;

    :hover {
      border: 1px solid #abbed1;
      border-radius: 4px;
    }
  }

  > canvas {
    width: 100%;
    min-height: 200px;
    max-height: 20vh;
    border: 2px solid #abbed1;
    border-radius: 4px;
  }
`

export const LabelArea = styled.div`
  display: grid;
  min-height: 2.5rem;
  grid-template-columns: 1fr auto;
  align-items: flex-end;
  margin-bottom: 0.25rem;
`
