import AWS from 'aws-sdk'
import { GetObjectOutput, GetObjectRequest } from 'aws-sdk/clients/s3'

// definir parâmetros de acesso ao S3
const S3_BUCKET = window.s3BucketName || ''
const REGION = window.region

AWS.config.update({
  accessKeyId: window.accessKeyId,
  secretAccessKey: window.secretAccessKey
})

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION
})

type S3ObjectData = {
  contentLength: GetObjectOutput['ContentLength']
  contentType: GetObjectOutput['ContentType']
  body: GetObjectOutput['Body']
  image?: string
}

const useGetObjectS3 = () => {
  const getObject = (filename: string) => {
    return new Promise<S3ObjectData>((resolve, reject) => {
      myBucket.getObject({ Key: filename } as GetObjectRequest, (err, file) => {
        if (err) {
          console.error('Error fetching S3 object:', err)
          reject(err)
          return
        }

        const params: S3ObjectData = {
          contentLength: file.ContentLength,
          contentType: file.ContentType,
          body: file.Body
        }

        // Se o objeto for uma imagem, converte para base64
        if (file.ContentType?.startsWith('image/')) {
          params.image = `data:${file.ContentType};base64,${encode(file.Body)}`
        }

        resolve(params)
      })
    })
  }
  return {
    getObject
  }
}

function encode(data?: GetObjectOutput['Body']) {
  if (
    !(
      typeof data === 'string' ||
      data instanceof Buffer ||
      data instanceof Uint8Array
    )
  ) {
    return ''
  }

  const buf = Buffer.from(data)
  const base64 = buf.toString('base64')
  return base64
}

export default useGetObjectS3
